import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    ref: "scrollWrapper",
    class: "scroll-wrapper",
    onScroll: _cache[0] || (_cache[0] = (...args) => $setup.handleScroll && $setup.handleScroll(...args)),
    style: _normalizeStyle($props.styleObj)
  }, [_createElementVNode("div", {
    style: _normalizeStyle({
      height: $setup.totalHeight + 'px'
    })
  }, null, 4), _createElementVNode("div", {
    ref: "visibleList",
    class: "visible-list",
    style: _normalizeStyle({
      transform: `translateY(${$setup.offsetY}px)`
    })
  }, [_renderSlot(_ctx.$slots, "default", {
    items: $setup.visibleItems,
    start: $setup.startIndex
  }, undefined, true)], 4)], 36);
}