import { ref, reactive, onMounted, watch } from "@vue/runtime-core";
import utils from "../utils/time";
import SvgIcon from "@/components/SvgIcon.vue";
import dayjs from "dayjs";
import _ from "lodash";
export default {
  components: {
    SvgIcon
  },
  props: {
    // modelValue: { type: Object },
    startTime: String,
    endTime: String,
    recentDay: String,
    dynamic: Number,
    fromDayToDay: Number,
    useServerTime: Boolean,
    position: {
      type: String,
      default: "topLeft"
    },
    showQuick: {
      type: Boolean,
      default: true
    },
    showUseServerTime: {
      type: Boolean,
      default: true
    },
    showDynamic: {
      type: Boolean,
      default: true
    },
    useType: {
      type: String,
      default: ""
    },
    isdeletion: {
      type: Boolean,
      default: false
    },
    showHeader: {
      default: true,
      type: Boolean
    },
    isRequired: {
      default: true,
      type: Boolean
    },
    selectDateRange: {
      type: Array,
      default: () => []
    }
  },
  setup(props, {
    emit
  }) {
    const isOperate = ref(false);
    const visible = ref(false);
    const leftWeekList = ref([]);
    const weekHeader = ref([]);
    const rightWeekList = ref([]);
    const startYearLabel = ref(null);
    const startMonthLabel = ref(null);
    const endYearLabel = ref(null);
    const endMonthLabel = ref(null);
    const startSelectDay = ref(null);
    const endSelectDay = ref(null);
    const hoverSelectDay = ref(null);
    const direction = ref(null);
    const firstSelectDay = ref(null);
    const showTime = ref(null);
    const quickList = ref([]); //快捷选择列表
    //备份初始回显状态
    const formData = reactive({
      startTime: null,
      endTime: null,
      recentDay: null,
      // eg: 7-1 动态时间
      dynamic: 1,
      // 1动态时间 0 静态时间
      fromDayToDay: 0,
      // 1动态时间 从某固定日至今
      useServerTime: false // true 开启服务器时间
    });
    const currentDay = ref(dayjs(new Date()).format("YYYYMMDD"));
    onMounted(() => {
      if (!formData.startTime || !formData.endTime) {
        hideLeft(dayjs().add(-1, "month").startOf("month").valueOf());
        hideRight(dayjs(new Date()).valueOf());
        handleDynamic(formData.dynamic); //初始化快捷键
      }
    });
    const hideLeft = date => {
      //获取当前年
      let year = utils.getFullYear(date);
      //获取当前月
      let month = utils.getMonth(date);
      // 获取当月的第一天
      let currentFirstDay = utils.getCurrentMonthDay(year, month, 1);
      let weekNum = utils.getDay(currentFirstDay) == 0 ? 7 : utils.getDay(currentFirstDay);
      //获得当前面板月的第一天；
      let startDay = currentFirstDay - (weekNum - 1) * 24 * 60 * 60 * 1000;
      //得到所有的日期
      leftWeekList.value = utils.getPanelDate(startDay);
      weekHeader.value = utils.getWeekList();
      startYearLabel.value = year;
      startMonthLabel.value = month;
    };
    const hideRight = date => {
      //获取当前年
      let year = utils.getFullYear(date);
      //获取当前月
      let month = utils.getMonth(date);
      // 获取当月的第一天
      let currentFirstDay = utils.getCurrentMonthDay(year, month, 1);
      let weekNum = utils.getDay(currentFirstDay) == 0 ? 7 : utils.getDay(currentFirstDay);
      //获得当前面板月的第一天；
      let startDay = currentFirstDay - (weekNum - 1) * 24 * 60 * 60 * 1000;
      //得到所有的日期
      rightWeekList.value = utils.getPanelDate(startDay);
      weekHeader.value = utils.getWeekList();
      endYearLabel.value = year;
      endMonthLabel.value = month;
    };
    const handleStartLeftPanel = () => {
      let currentFirstDay = dayjs(`${startYearLabel.value}-${startMonthLabel.value}-01`).add(-1, "month").startOf("month").valueOf();
      hideLeft(dayjs(currentFirstDay).valueOf());
    };
    const handleStartLeftPanelYear = () => {
      let currentFirstDay = dayjs(`${startYearLabel.value - 1}-${startMonthLabel.value}-01`);
      hideLeft(dayjs(currentFirstDay).valueOf());
    };
    const handleStartRightPanel = () => {
      if (dayjs(`${startYearLabel.value}-${startMonthLabel.value}-01`).add(1, "month").startOf("month").valueOf() == dayjs(`${endYearLabel.value}-${endMonthLabel.value}-01`).valueOf()) {
        return;
      }
      let currentFirstDay = dayjs(`${startYearLabel.value}-${startMonthLabel.value}-01`).add(1, "month").startOf("month").valueOf();
      hideLeft(dayjs(currentFirstDay).valueOf());
    };
    const handleStartRightPanelYear = () => {
      if (startYearLabel.value + 1 > endYearLabel.value) {
        return;
      }
      let currentFirstDay = dayjs(`${Number(startYearLabel.value) + 1}-${startMonthLabel.value}-01`);
      hideLeft(dayjs(currentFirstDay).valueOf());
    };
    const handleEndLeftPanel = () => {
      if (dayjs(`${startYearLabel.value}-${startMonthLabel.value}-01`).add(1, "month").startOf("month").valueOf() == dayjs(`${endYearLabel.value}-${endMonthLabel.value}-01`).valueOf()) {
        return;
      }
      let currentFirstDay = dayjs(`${endYearLabel.value}-${endMonthLabel.value}-01`).add(-1, "month").startOf("month").valueOf();
      hideRight(dayjs(currentFirstDay).valueOf());
    };
    const handleEndLeftPanelYear = () => {
      if (dayjs(`${startYearLabel.value}-${startMonthLabel.value}-01`).startOf("month").valueOf() >= dayjs(`${endYearLabel.value - 1}-${endMonthLabel.value}-01`).startOf("month").valueOf()) {
        return;
      }
      let currentFirstDay = dayjs(`${endYearLabel.value - 1}-${endMonthLabel.value}-01`);
      hideRight(dayjs(currentFirstDay).valueOf());
    };
    const handleEndRightPanel = () => {
      let currentFirstDay = dayjs(`${endYearLabel.value}-${endMonthLabel.value}-01`).add(1, "month").startOf("month").valueOf();
      hideRight(dayjs(currentFirstDay).valueOf());
    };
    const handleEndRightPanelYear = () => {
      let currentFirstDay = dayjs(`${Number(endYearLabel.value) + 1}-${endMonthLabel.value}-01`);
      hideRight(dayjs(currentFirstDay).valueOf());
    };
    const handleSelectTime = item => {
      //既没有开始时间又没有结束时间
      if (startSelectDay.value == null && endSelectDay.value == null) {
        firstSelectDay.value = `${item.year}${item.month}${item.day}`;
        startSelectDay.value = `${item.year}${item.month}${item.day}`;
        endSelectDay.value = null;
        return;
      }
      //动态时间 选择某固定日至昨日或今日
      if (formData.startTime) {
        formData.fromDayToDay = 0;
      }
      //向右
      if (direction.value == "right") {
        startSelectDay.value = firstSelectDay.value;
        endSelectDay.value = `${item.year}${item.month}${item.day}`;
        //获取开始结束时间
        formData.startTime = startSelectDay.value;
        formData.endTime = endSelectDay.value;
        //  清空勾选的交互
        // formData.fromDayToDay = 0;
        startSelectDay.value = null;
        endSelectDay.value = null;
        hoverSelectDay.value = null;
        direction.value = null;
        formData.recentDay = `${Math.abs(utils.diffDay(formData.startTime, dayjs()))}-${Math.abs(utils.diffDay(formData.endTime, dayjs()))}`;
        showTime.value = utils.transferLabel(formData.dynamic, formData.startTime, formData.endTime, formData.recentDay, formData.fromDayToDay);
      } else if (direction.value == "left") {
        startSelectDay.value = `${item.year}${item.month}${item.day}`;
        endSelectDay.value = firstSelectDay.value;
        //获取开始结束时间
        formData.startTime = startSelectDay.value;
        formData.endTime = endSelectDay.value;
        //  清空勾选的交互
        formData.recentDay = `${Math.abs(utils.diffDay(formData.startTime, dayjs()))}-${Math.abs(utils.diffDay(formData.endTime, dayjs()))}`;
        startSelectDay.value = null;
        endSelectDay.value = null;
        hoverSelectDay.value = null;
        direction.value = null;
        showTime.value = utils.transferLabel(formData.dynamic, formData.startTime, formData.endTime, formData.recentDay, formData.fromDayToDay);
      } else {
        startSelectDay.value = firstSelectDay.value;
        endSelectDay.value = `${item.year}${item.month}${item.day}`;
        //获取开始结束时间
        formData.startTime = startSelectDay.value;
        formData.endTime = endSelectDay.value;
        //  清空勾选的交互

        startSelectDay.value = null;
        endSelectDay.value = null;
        hoverSelectDay.value = null;
        direction.value = null;
        formData.recentDay = `${Math.abs(utils.diffDay(formData.startTime, dayjs()))}-${Math.abs(utils.diffDay(formData.endTime, dayjs()))}`;
        showTime.value = utils.transferLabel(formData.dynamic, formData.startTime, formData.endTime, formData.recentDay, formData.fromDayToDay);
      }
      isOperate.value = true;
      emit("update:startTime", formData.startTime);
      emit("update:endTime", formData.endTime);
      emit("update:recentDay", formData.recentDay);
      emit("update:fromDayToDay", formData.fromDayToDay);
      // emit("changeTime");
    };
    const renderStyle = (year, month, day) => {
      let date = `${year}${month}${day}`;
      return direction.value == "left" && endSelectDay.value && hoverSelectDay.value <= dayjs(endSelectDay.value).valueOf() && dayjs(date).valueOf() < dayjs(endSelectDay.value).valueOf() && dayjs(date).valueOf() >= hoverSelectDay.value || direction.value == "right" && startSelectDay.value && hoverSelectDay.value >= dayjs(startSelectDay.value).valueOf() && dayjs(date).valueOf() > dayjs(startSelectDay.value).valueOf() && dayjs(date).valueOf() <= hoverSelectDay.value;
    };
    const renderSelectLeftStyle = (year, month, day) => {
      let date = `${year}${month}${day}`;
      return dayjs(date).valueOf() > dayjs(formData.startTime).valueOf() && month <= startMonthLabel.value && dayjs(date).valueOf() < dayjs(formData.endTime).valueOf();
    };
    const renderSelectRightStyle = (year, month, day) => {
      let date = `${year}${month}${day}`;
      return dayjs(date).valueOf() > dayjs(formData.startTime).valueOf() && month == endMonthLabel.value && dayjs(date).valueOf() < dayjs(formData.endTime).valueOf();
    };
    const onMousemove = _.debounce(e => {
      if (e.target.dataset.date && (startSelectDay.value || endSelectDay.value) && hoverSelectDay.value !== dayjs(e.target.dataset.date).valueOf()) {
        if (dayjs(e.target.dataset.date).valueOf() >= dayjs(firstSelectDay.value).valueOf()) {
          //     向右选择
          startSelectDay.value = firstSelectDay.value;
          endSelectDay.value = null;
          direction.value = "right";
        } else {
          //     向左选择
          startSelectDay.value = null;
          endSelectDay.value = firstSelectDay.value;
          direction.value = "left";
        }
        hoverSelectDay.value = dayjs(e.target.dataset.date).valueOf();
      }
    });

    //切换动静态时间
    const handleDynamic = (e, flag) => {
      if (e === 0 && props.useType == "datapanel") {
        formData.recentDay = `${Math.abs(utils.diffDay(formData.startTime, dayjs()))}-${Math.abs(utils.diffDay(formData.endTime, dayjs()))}`;
        emit("update:recentDay", formData.recentDay);
      }
      // flag 仅用于初次默认渲染自某日至今
      formData.dynamic = e;
      if (e == 1) {
        quickList.value = utils.getDynamicList();
      } else {
        quickList.value = utils.getStaticList();
      }
      if (formData.startTime && formData.endTime) {
        showTime.value = utils.transferLabel(formData.dynamic, formData.startTime, formData.endTime, formData.recentDay, formData.fromDayToDay);
      }
      emit("update:dynamic", e);
      emit("update:fromDayToDay", flag ? flag : 0);
    };
    //快捷选择
    const handleQuickTime = item => {
      firstSelectDay.value = null;
      startSelectDay.value = null;
      endSelectDay.value = null;
      formData.startTime = null;
      formData.endTime = null;
      switch (item.label) {
        case "昨日":
          formData.startTime = dayjs().startOf("day").add(-1, "day").format("YYYYMMDD");
          formData.endTime = dayjs().startOf("day").add(-1, "day").format("YYYYMMDD");
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case "今日":
          formData.startTime = dayjs().startOf("day").add(0, "day").format("YYYYMMDD");
          formData.endTime = dayjs().startOf("day").add(0, "day").format("YYYYMMDD");
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case "上周":
          dayjs().day(1);
          formData.startTime = dayjs().add(-1, "week").startOf("week")
          // .add(1, "day")
          .format("YYYYMMDD");
          formData.endTime = dayjs().add(-1, "week").endOf("week")
          // .add(1, "day")
          .format("YYYYMMDD");
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case "本周":
          formData.startTime = dayjs().startOf("week")
          // .add(1, "day")
          .format("YYYYMMDD");
          formData.endTime = dayjs().format("YYYYMMDD");
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case "上月":
          formData.startTime = dayjs().add(-1, "month").startOf("month").format("YYYYMMDD");
          formData.endTime = dayjs().add(-1, "month").endOf("month").format("YYYYMMDD");
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case "本月":
          formData.startTime = dayjs().startOf("month").format("YYYYMMDD");
          formData.endTime = dayjs().format("YYYYMMDD");
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case "过去7天":
          formData.startTime = dayjs().startOf("day").add(-7, "day").format("YYYYMMDD");
          formData.endTime = dayjs().endOf("day").add(-1, "day").format("YYYYMMDD");
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case "过去14天":
          formData.startTime = dayjs().startOf("day").add(-14, "day").format("YYYYMMDD");
          formData.endTime = dayjs().endOf("day").add(-1, "day").format("YYYYMMDD");
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case "过去30天":
          formData.startTime = dayjs().startOf("day").add(-30, "day").format("YYYYMMDD");
          formData.endTime = dayjs().endOf("day").add(-1, "day").format("YYYYMMDD");
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case "最近7天":
          formData.startTime = dayjs().startOf("day").add(-6, "day").format("YYYYMMDD");
          formData.endTime = dayjs().endOf("day").add(0, "day").format("YYYYMMDD");
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case "最近14天":
          formData.startTime = dayjs().startOf("day").add(-13, "day").format("YYYYMMDD");
          formData.endTime = dayjs().endOf("day").add(0, "day").format("YYYYMMDD");
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case "最近30天":
          formData.startTime = dayjs().startOf("day").add(-29, "day").format("YYYYMMDD");
          formData.endTime = dayjs().endOf("day").add(0, "day").format("YYYYMMDD");
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case '最近60天':
          formData.startTime = dayjs().startOf('day').add(-59, 'day').format('YYYYMMDD');
          formData.endTime = dayjs().endOf('day').add(0, 'day').format('YYYYMMDD');
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case '最近365天':
          formData.startTime = dayjs().startOf('day').add(-364, 'day').format('YYYYMMDD');
          formData.endTime = dayjs().endOf('day').add(0, 'day').format('YYYYMMDD');
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case '最近730天':
          formData.startTime = dayjs().startOf('day').add(-729, 'day').format('YYYYMMDD');
          formData.endTime = dayjs().endOf('day').add(0, 'day').format('YYYYMMDD');
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 0;
          break;
        case "自固定日至昨日":
          endSelectDay.value = dayjs().endOf("day").add(-1, "day").format("YYYYMMDD");
          formData.endTime = dayjs().endOf("day").add(-1, "day").format("YYYYMMDD");
          firstSelectDay.value = dayjs().endOf("day").add(-1, "day").format("YYYYMMDD");
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 1;
          break;
        case "自固定日至今日":
          endSelectDay.value = dayjs().endOf("day").add(0, "day").format("YYYYMMDD");
          formData.endTime = dayjs().endOf("day").add(0, "day").format("YYYYMMDD");
          firstSelectDay.value = dayjs().endOf("day").add(0, "day").format("YYYYMMDD");
          formData.recentDay = item.recentDay;
          formData.fromDayToDay = 1;
          break;
        case "自某日至昨日":
          endSelectDay.value = dayjs().endOf("day").add(-1, "day").format("YYYYMMDD");
          formData.endTime = dayjs().endOf("day").add(-1, "day").format("YYYYMMDD");
          firstSelectDay.value = dayjs().endOf("day").add(-1, "day").format("YYYYMMDD");
          formData.fromDayToDay = 0;
          break;
        case "自某日至今日":
          endSelectDay.value = dayjs().endOf("day").add(0, "day").format("YYYYMMDD");
          formData.endTime = dayjs().endOf("day").add(0, "day").format("YYYYMMDD");
          firstSelectDay.value = dayjs().endOf("day").add(0, "day").format("YYYYMMDD");
          formData.fromDayToDay = 0;
          break;
        default:
          break;
      }
      // formData.recentDay = `${Math.abs(
      //   utils.diffDay(formData.startTime, dayjs())
      // )}-${Math.abs(utils.diffDay(formData.endTime, dayjs()))}`;
      showTime.value = utils.transferLabel(formData.dynamic, formData.startTime, formData.endTime, formData.recentDay, formData.fromDayToDay);
      emit("update:startTime", formData.startTime);
      emit("update:endTime", formData.endTime);
      emit("update:recentDay", formData.recentDay);
      emit("update:fromDayToDay", formData.fromDayToDay);
      isOperate.value = true;
      // emit("changeTime");
    };
    const handleSwitchServer = e => {
      emit("update:useServerTime", e);
      isOperate.value = true;
      // emit("changeTime");
    };
    const deleteDate = () => {
      // console.log(formData);
      formData.startTime = "";
      formData.endTime = "";
      showTime.value = utils.transferLabel(formData.dynamic, formData.startTime, formData.endTime, formData.recentDay, formData.fromDayToDay);
      emit("update:startTime", formData.startTime);
      emit("update:endTime", formData.endTime);
    };
    const changeShowTime = () => {
      showTime.value = utils.transferLabel(formData.dynamic, formData.startTime, formData.endTime, formData.recentDay, formData.fromDayToDay);
    };
    watch(() => [props.startTime, props.endTime, props.recentDay, props.dynamic, props.fromDayToDay, props.useServerTime], (n, o) => {
      // formData.startTime = n[0];
      // formData.endTime = n[1];
      // if (n[2]) {
      //   formData.recentDay = n[2];
      // }
      // formData.dynamic = !n[3] ? 0 : 1;
      // formData.fromDayToDay = n[4];
      // formData.useServerTime = n[5];
      changeShowTime();
      if (n != o) {
        if (!n[0] || !n[1]) {
          formData.startTime = n[0];
          formData.endTime = n[1];
          formData.fromDayToDay = n[4];
          formData.dynamic = !n[3] ? 0 : 1;
          formData.useServerTime = n[5];
          changeShowTime();
          return; // 快捷选择 自某日
        }
        // 反显 动态 静态 区分
        if (n[3] == 1) {
          //区分 是否有自固定日至昨日 或今日
          if (n[4] == 1) {
            formData.startTime = n[0];
            // ==1 为昨日 ==0 为今日
            if (n[2].split("-")[1] == 1) {
              formData.endTime = dayjs().startOf("day").add(-1, "day").format("YYYYMMDD");
              formData.recentDay = `${Math.abs(utils.diffDay(n[0], dayjs()))}-1`; // eg: 7-1 动态时间
            } else {
              formData.endTime = dayjs().startOf("day").add(0, "day").format("YYYYMMDD");
              formData.recentDay = `${Math.abs(utils.diffDay(n[0], dayjs()))}-0`; // eg: 7-1 动态时间
            }
          } else {
            let arr = utils.getDynamicList().filter(item => {
              return item.recentDay == n[2];
            });
            if (arr.length) {
              handleQuickTime(arr[0]);
            } else {
              if (n[2]) {
                let temp = n[2].split("-");
                formData.startTime = dayjs().startOf("day").add(-Number(temp[0]), "day").format("YYYYMMDD");
                formData.endTime = dayjs().startOf("day").add(-Number(temp[1]), "day").format("YYYYMMDD");
              }
              formData.recentDay = n[2];
            }
          }

          // formData.dynamic = n[3];
          formData.dynamic = !n[3] ? 0 : 1;
          formData.fromDayToDay = n[4]; // 1动态时间 从某固定日至今
          formData.useServerTime = n[5]; // true 开启服务器时间

          if (utils.getMonth(formData.startTime) == utils.getMonth(formData.endTime)) {
            hideLeft(dayjs(formData.endTime).add(-1, "month").startOf("month").valueOf());
            hideRight(dayjs(formData.endTime).valueOf());
            handleDynamic(formData.dynamic, formData.fromDayToDay); //初始化快捷键
          } else {
            hideLeft(dayjs(formData.startTime).valueOf());
            hideRight(dayjs(formData.endTime).valueOf());
            handleDynamic(formData.dynamic, formData.fromDayToDay); //初始化快捷键
          }
        } else {
          formData.startTime = n[0];
          formData.endTime = n[1];
          formData.recentDay = n[2] ? n[2] : `${Math.abs(utils.diffDay(n[0], dayjs()))}-${Math.abs(utils.diffDay(n[1], dayjs()))}`; // eg: 7-1 动态时间
          // formData.dynamic = n[3]; // 1动态时间 0 静态时间
          formData.dynamic = !n[3] ? 0 : 1;
          formData.fromDayToDay = n[4]; // 1动态时间 从某固定日至今
          formData.useServerTime = n[5]; // true 开启服务器时间
          if (utils.getMonth(n[0]) == utils.getMonth(n[1])) {
            hideLeft(dayjs(n[1]).add(-1, "month").startOf("month").valueOf());
            hideRight(dayjs(n[1]).valueOf());
            handleDynamic(formData.dynamic); //初始化快捷键
          } else {
            hideLeft(dayjs(n[0]).valueOf());
            hideRight(dayjs(n[1]).valueOf());
            handleDynamic(formData.dynamic); //初始化快捷键
          }
        }
      }
    }, {
      immediate: true,
      deep: true
    });
    watch(() => visible.value, (n, o) => {
      if (!n && o && isOperate.value == true) {
        emit("changeTime");
        isOperate.value = false;
      }
    });
    return {
      currentDay,
      visible,
      hideLeft,
      hideRight,
      leftWeekList,
      rightWeekList,
      weekHeader,
      formData,
      firstSelectDay,
      startYearLabel,
      startMonthLabel,
      endYearLabel,
      endMonthLabel,
      handleStartLeftPanel,
      handleStartLeftPanelYear,
      handleStartRightPanel,
      handleStartRightPanelYear,
      handleEndLeftPanel,
      handleEndLeftPanelYear,
      handleEndRightPanel,
      handleEndRightPanelYear,
      handleSelectTime,
      startSelectDay,
      endSelectDay,
      hoverSelectDay,
      onMousemove,
      renderStyle,
      renderSelectLeftStyle,
      renderSelectRightStyle,
      dayjs,
      handleDynamic,
      showTime,
      quickList,
      handleQuickTime,
      handleSwitchServer,
      deleteDate,
      changeShowTime,
      isOperate
    };
  }
};