import dayjs from "dayjs";
//以数值返回天（1-31）
const getDate = (date) => {
  return dayjs(date).format("DD");
};
// 以数值获取周名（0-6）
const getDay = (date) => {
  return dayjs(date).format("d");
};
const formatWeek = (name) => {
  let weekName = "一";
  switch (name) {
    case "0":
      weekName = "一";
      break;
    case "1":
      weekName = "二";
      break;
    case "2":
      weekName = "三";
      break;
    case "3":
      weekName = "四";
      break;
    case "4":
      weekName = "五";
      break;
    case "5":
      weekName = "六";
      break;
    default:
      weekName = "日";
      break;
  }
  return weekName;
};
//获取当前月份
const getMonth = (date) => {
  return dayjs(date).format("MM");
};

//获取四位的年（yyyy）
const getFullYear = (date) => {
  return dayjs(date).format("YYYY");
};

//获取年月日
const getYearMonthDay = (date) => {
  let year = dayjs(date).format("YYYY");
  let month = dayjs(date).format("MM");
  let day = dayjs(date).format("DD");

  return { year, month, day };
};
// 格式化日期
const formatDate = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};
//获取当前月的第一天
const getCurrentMonthDay = (year, month, day) => {
  return dayjs(new Date(year, month - 1, day)).valueOf();
};

//获得所有的日期
const getPanelDate = (startTime) => {
  let calenderArray = [];
  for (let i = 0; i < 42; i++) {
    calenderArray.push({
      date: dayjs(startTime + i * 24 * 60 * 60 * 1000),
      year: dayjs(startTime + i * 24 * 60 * 60 * 1000).format("YYYY"),
      month: dayjs(startTime + i * 24 * 60 * 60 * 1000).format("MM"),
      day: dayjs(startTime + i * 24 * 60 * 60 * 1000).format("DD"),
    });
  }
  return calenderArray;
};
const getWeekList = () => {
  return ["一", "二", "三", "四", "五", "六", "日"];
};
// 时间显示
const transferLabel = (
  dynamic,
  startTime,
  endTime,
  recentDay,
  fromDayToDay
) => {
  let name = null;
  if (!startTime || !endTime) {
    name = "开始日期 ~ 结束日期"
  } else {
    if (dynamic == 0) {
      //静态时间
      name = `${startTime ? dayjs(startTime).format("YYYY-MM-DD") : ""} ~ ${endTime ? dayjs(endTime).format("YYYY-MM-DD") : ""
        }`;
    } else {
      let arr = getDynamicList().filter((item) => {
        return recentDay == item.recentDay && fromDayToDay == item.fromDayToDay;
      });
      if (arr.length) {
        name = arr[0].label;
      } else {
        let start = recentDay.split("-")[0]
          ? Number(recentDay.split("-")[0])
          : "",
          end = recentDay.split("-")[1] ? Number(recentDay.split("-")[1]) : "";
        name = `${transferDayName(start)}至${transferDayName(end)}`;
      }
    }
  }

  return name;
};
const transferDayName = (num) => {
  let name = null;
  switch (num) {
    case 0:
      name = "今日";
      break;
    case 1:
      name = "昨日";
      break;
    default:
      name = `过去${num}天`;
      break;
  }

  return name;
};
const getStaticList = () => {
  return [
    { label: "昨日", recentDay: "1-1", fromDayToDay: 0 },
    { label: "今日", recentDay: "0-0", fromDayToDay: 0 },
    { label: "上周", recentDay: "W1", fromDayToDay: 0 },
    { label: "本周", recentDay: "W0", fromDayToDay: 0 },
    { label: "上月", recentDay: "M1", fromDayToDay: 0 },
    { label: "本月", recentDay: "M0", fromDayToDay: 0 },
    { label: "过去7天", recentDay: "7-1", fromDayToDay: 0 },
    { label: "过去14天", recentDay: "14-1", fromDayToDay: 0 },
    { label: "过去30天", recentDay: "30-1", fromDayToDay: 0 },
    { label: "最近7天", recentDay: "6-0", fromDayToDay: 0 },
    { label: "最近14天", recentDay: "13-0", fromDayToDay: 0 },
    { label: "最近30天", recentDay: "29-0", fromDayToDay: 0 },
    { label: "最近60天", recentDay: "59-0", fromDayToDay: 0 },
    // { label: "最近365天", recentDay: "364-0", fromDayToDay: 0 },
    // { label: "最近730天", recentDay: "729-0", fromDayToDay: 0 },
    // { label: "自某日至昨日", fromDayToDay: 0, recentDay: "x1" },
    // { label: "自某日至今日", fromDayToDay: 0, recentDay: "x2" },
  ];
};
const getDynamicList = () => {
  return [
    { label: "昨日", recentDay: "1-1", fromDayToDay: 0 },
    { label: "今日", recentDay: "0-0", fromDayToDay: 0 },
    { label: "上周", recentDay: "W1", fromDayToDay: 0 },
    { label: "本周", recentDay: "W0", fromDayToDay: 0 },
    { label: "上月", recentDay: "M1", fromDayToDay: 0 },
    { label: "本月", recentDay: "M0", fromDayToDay: 0 },
    { label: "过去7天", recentDay: "7-1", fromDayToDay: 0 },
    { label: "过去30天", recentDay: "30-1", fromDayToDay: 0 },
    { label: "最近7天", recentDay: "6-0", fromDayToDay: 0 },
    { label: "最近30天", recentDay: "29-0", fromDayToDay: 0 },
    // { label: "自固定日至昨日", fromDayToDay: 1, recentDay: "x1" },
    // { label: "自固定日至今日", fromDayToDay: 1, recentDay: "x2" },
  ];
};
const diffDay = (beforeTime, currentTime) => {
  return dayjs(beforeTime).diff(currentTime, "day");
};
export default {
  getDate,
  getDay,
  getFullYear,
  getMonth,
  getYearMonthDay,
  getCurrentMonthDay,
  formatWeek,
  formatDate,
  getPanelDate,
  getWeekList,
  transferLabel,
  getStaticList,
  getDynamicList,
  diffDay,
  transferDayName,
};
