import "core-js/modules/es.array.push.js";

import { ref, watch, nextTick } from 'vue';
export default {
  name: 'VirtualScroll',
  props: {
    itemHeight: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    visibleCount: {
      type: Number,
      required: true
    },
    styleObj: {
      default: () => {},
      type: Object
    }
  },
  emits: ['update:startIndex'],
  setup(props, {
    emit
  }) {
    const totalHeight = ref(0);
    const visibleItems = ref([]);
    const offsetY = ref(0);
    const startIndex = ref(0);
    const endIndex = ref(0);
    const currentBufferZone = ref(null);
    const bufferZoneHeight = 20;
    const scrollWrapper = ref(null);
    const visibleList = ref(null);

    // 监听滚动事件，更新可见区域的列表项
    const handleScroll = () => {
      updateVisibleItems();
      emit('update:startIndex', startIndex.value);
    };

    // 计算可见区域的列表项
    const updateVisibleItems = () => {
      var _scrollWrapper$value;
      const scrollTop = (_scrollWrapper$value = scrollWrapper.value) === null || _scrollWrapper$value === void 0 ? void 0 : _scrollWrapper$value.scrollTop;

      // 计算缓冲区的高度，避免滚动过程中频繁计算可见项
      const bufferHeight = bufferZoneHeight * props.itemHeight;
      const bufferTop = scrollTop - bufferHeight;
      const bufferBottom = scrollTop + props.visibleCount * props.itemHeight + bufferHeight;

      // 计算新的startIndex，endIndex
      const startIndexValue = Math.floor(scrollTop / props.itemHeight);
      const endIndexValue = Math.min(startIndexValue + props.visibleCount, props.items.length);
      if (startIndexValue !== startIndex.value || endIndexValue !== endIndex.value || !currentBufferZone.value || bufferTop < currentBufferZone.value.top || bufferBottom > currentBufferZone.value.bottom) {
        const visibleItemsValue = [];
        for (let i = startIndexValue; i < endIndexValue; i++) {
          visibleItemsValue.push(props.items[i]);
        }
        visibleItems.value = visibleItemsValue;

        // 更新 offsetY，使用 translateY 实现滚动的效果
        offsetY.value = scrollTop - scrollTop % props.itemHeight;

        // 更新 currentBufferZone，用来判断是否需要更新可见项
        const bufferZoneTop = Math.max(0, startIndexValue - bufferZoneHeight);
        const bufferZoneBottom = Math.min(endIndexValue + bufferZoneHeight, props.items.length);
        currentBufferZone.value = {
          top: bufferZoneTop * props.itemHeight,
          bottom: bufferZoneBottom * props.itemHeight
        };

        // 更新 startIndexValue，endIndexValue
        startIndex.value = startIndexValue;
        endIndex.value = endIndexValue;
      }
    };

    // 计算列表总高度
    const updateTotalHeight = () => {
      totalHeight.value = props.itemHeight * props.items.length;
    };

    // 监听 props 的变化，更新列表总高度和可见区域的列表项
    watch(() => props.items, () => {
      updateTotalHeight();
      updateVisibleItems();
    }, {
      immediate: true
    });
    watch(() => props.visibleCount, () => {
      nextTick(() => {
        updateVisibleItems();
      });
    }, {
      immediate: true
    });
    return {
      totalHeight,
      visibleItems,
      offsetY,
      startIndex,
      scrollWrapper,
      visibleList,
      handleScroll,
      updateVisibleItems,
      updateTotalHeight
    };
  }
};